import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import PostBlurb from '../components/postBlurb'
import Navigation from '../components/navigation'

const Index = ({ data, pageContext: { nextPagePath, previousPagePath } }) => {
  const {
    allMdx: { edges: posts },
  } = data

  return (
    <>
      <SEO title='Posts' />
      <Layout>
        {posts.map(({ node }) => {
          const {
            id,
            excerpt,
            frontmatter: {
              title,
              date,
              path,
              coverImage,
              blurb,
              tags,
            },
          } = node

          return (
            <PostBlurb
              key={id}
              title={title}
              date={date}
              path={path}
              coverImage={coverImage}
              tags={tags}
              blurb={blurb || excerpt}
            />
          )
        })}

        <Navigation
          previousPath={previousPagePath}
          previousLabel="Newer posts"
          nextPath={nextPagePath}
          nextLabel="Older posts"
        />
      </Layout>
    </>
  )
}

Index.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    nextPagePath: PropTypes.string,
    previousPagePath: PropTypes.string,
  }),
}


export const query = graphql`
query($limit: Int, $skip: Int) {
  allMdx(
    filter: { fileAbsolutePath: { regex: "//posts//" } }
    sort: { fields: [frontmatter___date], order: DESC }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        excerpt
        frontmatter {
          title
          date
          path
          blurb
          tags
          coverImage {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                height: 200
                width: 200
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: INSIDE }
              )
            }
          }
        }
      }
    }
  }
}`

export default Index