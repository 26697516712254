import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { toKebabCase } from '../helpers/toKebabCase'

import * as style from '../styles/post.module.css'

const PostBlurb = ({
  title,
  date,
  path,
  coverImage,
  blurb,
  tags,
}) => (
  <div className={style.post}>
    <div className={style.postContent}>
      <h1 className={style.title}>
        <Link to={path}>{title}</Link>
      </h1>
      <div className={style.meta}>
        {date}
        {tags ? (
          <div className={style.tags}>
            {tags.map(tag => (
              <Link to={`/tag/${toKebabCase(tag)}`} key={toKebabCase(tag)}>
                <span className={style.tag}>#{tag}</span>
              </Link>
              ))}
          </div>
          ) : null}
      </div>

      <div className={style.coverPost}>
        {coverImage && (
          <GatsbyImage
            alt={`Post image related to ${title}` }
            image={coverImage.childImageSharp.gatsbyImageData}
            className={style.coverImage}
          />
          )}
        <p>{blurb}</p>
      </div>
    </div>
  </div>
  )

PostBlurb.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  path: PropTypes.string,
  coverImage: PropTypes.object,
  blurb: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
}

export default PostBlurb
